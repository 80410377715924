<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="church" href="/tabs/church">
          <ion-icon :icon="peopleCircle" />
          <ion-label>Church</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="media" href="/tabs/media">
          <ion-icon :icon="radioOutline" />
          <ion-label>Media</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="chat" href="/tabs/chat">
          <ion-icon :icon="chatbubbleOutline" />
          <ion-label>Chat</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="favorite" href="/tabs/favorite">
          <ion-icon :icon="heartOutline" />
          <ion-label>Favorite</ion-label>
        </ion-tab-button>

        <ion-tab-button>
          <ion-icon :icon="menu" />
          <ion-label>Menu</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { peopleCircle, radioOutline, chatbubbleOutline, heartOutline, menu } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      peopleCircle, 
      radioOutline, 
      chatbubbleOutline,
      heartOutline,
      menu,
    }
  }
}
</script>
<style>
/* .tab-selected {
    ion-icon {
        color: white;
    }

    ion-label{
        color: white;
    }
} */
</style>