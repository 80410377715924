<template>
  <ion-page>
      <ion-header>
        <ion-toolbar>
            <ion-title>
              <ion-thumbnail class="thumbnail">
                <a href="home"><ion-img src="/assets/images/logo.png"></ion-img></a>
              </ion-thumbnail>
            </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content :fullscreen="true">
        <ion-grid>
          <ion-row>
            <ion-col size-xl="4" size-lg="5" size-md="8" size-xs="12">
              <ion-card>
                  <ion-card-content>
                    <form @submit.prevent="handleLogin">
                      <ion-card>
                        <ion-item>
                          <h3>Please Login!</h3>
                        </ion-item>
                        <ion-item>
                          <ion-label position="floating">Username</ion-label>
                          <ion-input v-model="form.username" id="username" required></ion-input>
                        </ion-item>

                        <ion-item>
                          <ion-label position="floating">Password</ion-label>
                          <ion-input type="password" v-model="form.password" id="password" required></ion-input>
                        </ion-item>

                        <ion-item>
                          <ion-button type="submit" shape="round">
                            Login
                            <ion-icon slot="end" :icon="logIn"></ion-icon>
                          </ion-button>
                        </ion-item>
                        <ion-item>
                          <p>Or</p>
                        </ion-item>
                        <ion-item>
                          <ion-button type="button" shape="round" router-link="/signup">
                            Forgot password
                            <ion-icon slot="end" :icon="keyOutline"></ion-icon>
                          </ion-button>
                          <ion-button type="button" shape="round" router-link="/signup">
                            Sign Up
                            <ion-icon slot="end" :icon="personAdd"></ion-icon>
                          </ion-button>
                        </ion-item>
                      </ion-card>
                    </form>
                    <section>
                        <a href="/contact">Contact Us</a> | <a href="/privacy">Privacy</a> | <a href="/toc">Terms and conditions</a>
                    </section>
                  </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size-xl="8" size-lg="5" size-md="8" size-xs="12">
              <ion-card>
                <ion-card-content>
                  <ion-card-title class="red-title">
                  <h1>Jesus Nation Hub</h1>
                </ion-card-title>
                  This is a place for the churches of Jesus Christ. We provide services for church content.
                  Church pages, Media, Chats and more.
                  <ion-item-divider></ion-item-divider>
                      <ion-card-title class="red-title">
                        What we do for church of the lord
                      </ion-card-title>
                      <hr>
                  <ion-row class="no-padding">
                    <ion-col size="4" v-for="banner in banners" :key="banner.src">
                      <ion-img width="80" height="80" :src="banner.src"></ion-img>
                      <ion-label>{{banner.title}}</ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-item-divider></ion-item-divider>
                      <ion-card-title class="red-title">
                        Marchandise
                      </ion-card-title>
                      <hr>
                  <ion-row class="no-padding">
                    <ion-col size="4" v-for="banner in banners" :key="banner.src">
                      <ion-img :src="banner.src" height="400px"></ion-img>
                      <ion-label>{{banner.title}}</ion-label>
                    </ion-col>
                  </ion-row>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButton, IonInput, alertController, IonIcon, IonImg, IonThumbnail, IonItemDivider } from '@ionic/vue'
import { logIn, personAdd, keyOutline, } from 'ionicons/icons';
import { mapActions, mapGetters } from "vuex"
import { useRouter } from 'vue-router';
export default {
  name: 'Home',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonRow, IonCol, IonGrid, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButton, IonInput, IonIcon, IonImg, IonThumbnail, IonItemDivider },
  setup() {
    const router = useRouter();
    const banners = [{
      'title': 'Church',
      'src': '/assets/images/sermon-1.jpg'
    }, {
      'title': 'Worship',
      'src': '/assets/images/sermon-2.jpg'
    }, {
      'title': 'Prayer room',
      'src': '/assets/images/sermon-3.jpg'
    }];
    return {
      router,
      logIn,
      personAdd,
      keyOutline,
      banners
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode"
    ])
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form).then(() => {
        this.form.username = ""
        this.form.password = ""
        this.router.push("/tabs/tab1")
      }).catch(async (err: any) => {
        const errorAlert = await alertController
            .create({
              header: 'Failed',
              subHeader: 'Login in Failed',
              message: err,
              buttons: ['OK'],
            });
        await errorAlert.present()
      })
    }
  }
}
</script>

<style>
  ion-content {
    --background: url('/assets/images/background.jpg') no-repeat center/cover fixed;
  }

  .link {
    color: red;
    text-decoration: none;
  }

  .thumbnail {
    color:white;
    width: 60px;
    height: 40px;
}
</style>