import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import Tabs from '../views/Tabs.vue';
import  Contact from '@/views/Contact.vue';
import  Privacy from '@/views/Privacy.vue';
import  Toc from '@/views/Toc.vue';
import { TokenService } from "@/services/token.service";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    component: Home,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },{
    path: '/',
    redirect: '/tabs/church'
  },{
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'church'
      },
      {
        path: 'church',
        component: () => import('@/views/Church.vue')
      },
      {
        path: 'stream',
        component: () => import('@/views/Stream.vue')
      },
      {
        path: 'chat',
        component: () => import('@/views/Chat.vue')
      },
      {
        path: 'favorite',
        component: () => import('@/views/Favorite.vue')
      }
    ]
  },{
    path: '/contact',
    component: Contact,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },{
    path: '/privacy',
    component: Privacy,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },{
      path: '/toc',
      component: Toc,
      meta: {
        public: true,
        onlyWhenLoggedOut: true
      }
    }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  
  const loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next({
      path: '/home',
      query: { redirect: to.fullPath }
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/tabs/church')
  }

  next();
})

export default router
