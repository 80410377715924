<template>
  <ion-page>
      <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button  router-link="/home" >
                <ion-icon :icon="arrowBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <ion-title class="ion-center">
               Contact us
            </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col size-xl="6" size-lg="5" size-md="8" size-xs="12">
              <ion-card>
                  <ion-card-content>
                    <form @submit.prevent="handleLogin">
                      <ion-card>
                        <ion-item>
                          <h3>Please Login!</h3>
                        </ion-item>
                        <ion-item>
                          <ion-label position="floating">Username</ion-label>
                          <ion-input v-model="form.username" id="username" required></ion-input>
                        </ion-item>

                        <ion-item>
                          <ion-label position="floating">Password</ion-label>
                          <ion-input type="password" v-model="form.password" id="password" required></ion-input>
                        </ion-item>

                        <ion-item>
                          <ion-button type="submit" shape="round">
                            Login
                            <ion-icon slot="end" :icon="logIn"></ion-icon>
                          </ion-button>
                        </ion-item>
                        <ion-item>
                          <p>Or</p>
                        </ion-item>
                        <ion-item>
                          <ion-button type="button" shape="round" router-link="/signup">
                            Forgot password
                            <ion-icon slot="end" :icon="keyOutline"></ion-icon>
                          </ion-button>
                          <ion-button type="button" shape="round" router-link="/signup">
                            Sign Up
                            <ion-icon slot="end" :icon="personAdd"></ion-icon>
                          </ion-button>
                        </ion-item>
                      </ion-card>
                    </form>
                  </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size-xl="6" size-lg="5" size-md="8" size-xs="12">
              <ion-card>
                <ion-card-content>
                  <ion-card-title class="red-title">
                  <h1>Send us an Email</h1>
                </ion-card-title>
                  <ion-row class="no-padding">
                  Form here
                  </ion-row>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButtons, IonButton, IonInput, alertController, IonIcon} from '@ionic/vue'
import { logIn, personAdd, keyOutline, arrowBackOutline} from 'ionicons/icons';
import { mapActions, mapGetters } from "vuex"
import { useRouter } from 'vue-router';
export default {
  name: 'Contact',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonRow, IonCol, IonGrid, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButtons, IonButton, IonInput, IonIcon },
  setup() {
    const router = useRouter();
    return {
      router,
      logIn,
      personAdd,
      keyOutline,
      arrowBackOutline
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode"
    ])
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form).then(() => {
        this.form.username = ""
        this.form.password = ""
        this.router.push("/tabs/tab1")
      }).catch(async (err: any) => {
        const errorAlert = await alertController
            .create({
              header: 'Failed',
              subHeader: 'Login in Failed',
              message: err,
              buttons: ['OK'],
            });
        await errorAlert.present()
      })
    }
  }
}
</script>

<style>
    .link-a {
      color: white;
    }

    .icon-color {
      color: white;
    }
</style>