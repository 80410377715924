
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButtons, IonButton, IonInput, alertController, IonIcon } from '@ionic/vue'
import { logIn, personAdd, keyOutline, arrowBackOutline } from 'ionicons/icons';
import { mapActions, mapGetters } from "vuex"
import { useRouter } from 'vue-router';
export default {
  name: 'Contact',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonRow, IonCol, IonGrid, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButtons, IonButton, IonInput, IonIcon },
  setup() {
    const router = useRouter();
    return {
      router,
      logIn,
      personAdd,
      keyOutline, 
      arrowBackOutline
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode"
    ])
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form).then(() => {
        this.form.username = ""
        this.form.password = ""
        this.router.push("/tabs/tab1")
      }).catch(async (err: any) => {
        const errorAlert = await alertController
            .create({
              header: 'Failed',
              subHeader: 'Login in Failed',
              message: err,
              buttons: ['OK'],
            });
        await errorAlert.present()
      })
    }
  }
}
