
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { peopleCircle, radioOutline, chatbubbleOutline, heartOutline, menu } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      peopleCircle, 
      radioOutline, 
      chatbubbleOutline,
      heartOutline,
      menu,
    }
  }
}
