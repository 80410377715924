
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButton, IonInput, alertController, IonIcon, IonImg, IonThumbnail, IonItemDivider } from '@ionic/vue'
import { logIn, personAdd, keyOutline, } from 'ionicons/icons';
import { mapActions, mapGetters } from "vuex"
import { useRouter } from 'vue-router';
export default {
  name: 'Home',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonRow, IonCol, IonGrid, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonButton, IonInput, IonIcon, IonImg, IonThumbnail, IonItemDivider },
  setup() {
    const router = useRouter();
    const banners = [{
      'title': 'Church',
      'src': '/assets/images/sermon-1.jpg'
    }, {
      'title': 'Worship',
      'src': '/assets/images/sermon-2.jpg'
    }, {
      'title': 'Prayer room',
      'src': '/assets/images/sermon-3.jpg'
    }];
    return {
      router,
      logIn,
      personAdd,
      keyOutline,
      banners
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode"
    ])
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form).then(() => {
        this.form.username = ""
        this.form.password = ""
        this.router.push("/tabs/tab1")
      }).catch(async (err: any) => {
        const errorAlert = await alertController
            .create({
              header: 'Failed',
              subHeader: 'Login in Failed',
              message: err,
              buttons: ['OK'],
            });
        await errorAlert.present()
      })
    }
  }
}
